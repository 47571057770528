import React, {useEffect, useState} from 'react';
import styles from '../assets/scss/RoomDetails.module.scss'
import { useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {fetchBuildingsAsync, selectBuildingByRoomId, selectRoomById} from "../reducers/buildingSlice";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import LeftColumnComponent from "../components/desktop/RoomDetails/LeftColumn.component";
import RightColumnComponent from "../components/desktop/RoomDetails/RightColumn.component";
import BreadcrumbsComponent from "../components/common/Breadcrumbs/Breadcrumbs.component";
import MapComponent from "../components/common/Map/Map.component";
import { Helmet } from 'react-helmet';



const RoomDetailsPage = () => {
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>();
    const room = useSelector(selectRoomById(id));
    const building = useSelector(selectBuildingByRoomId(id))
    const [crumbs, setCrumbs] = useState<any[]>([
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Studios',
            link: '/spots'
        }
    ])
    useEffect(() => {
        //@ts-ignore
        dispatch(fetchBuildingsAsync());
    }, [dispatch]);

    useEffect(()=>{
        if (room && building) {
            if (room.seo && room.seo.title && room.seo.description) {

                document.title = room.seo.title; // Устанавливаем заголовок страницы
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', room.seo.description); // Устанавливаем описание страницы
                }
            }
            setCrumbs(old=>([...old, {
                text: building.address,
                link: `/building/${building.id}`
            },{
                text: room.title,
                link: `/room/${id}`
            }]))
        }
        return ()=> {
            document.title = 'GoSound: the first music coworking space in Cyprus'; // Устанавливаем заголовок страницы
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', 'GoSound is a music spaces rental service with simple convenient booking system. Our studios are perfect for musicians, vocalists, DJs and music teachers.'); // Устанавливаем описание страницы
            }
        }
    },[])

    return (
        <div className={styles.container}>
            {building && room && (
                <>
                    <div style={{marginLeft: -40}}>
                        <BreadcrumbsComponent crumbs={crumbs} />
                        <PageTitleComponent backLink={`/building/${building.id}`} title={room?.title} />
                    </div>
                    <div className={'row mt-25 mb-50'}>
                        {room && (
                            <>
                                <LeftColumnComponent  address={room?.address} includes={room?.includes} rating={room?.rating} reviews={room?.reviews} subtitle={room?.subtitle} title={room?.title} images={room?.images} description={room.description} />
                                <RightColumnComponent  draft={building?.id} currentRoom={room} currentBuilding = {building}/>
                            </>
                        )}

                    </div>
                    <div className={'mb-50'} style={{borderRadius: 8, overflow: 'hidden'}}>
                        <MapComponent height={150} openPopup={false} />
                    </div>
                </>
            )}
        </div>
    );
};

export default RoomDetailsPage;
export {};
