import React, {useEffect, useState} from 'react';
import styles from './LoginRegister.module.scss'
import InputMask from 'react-input-mask';

import {ReactComponent as Checkbox} from "../../../assets/images/svg/CheckBoxIcon.svg";
import {ReactComponent as EmptyCheckbox} from "../../../assets/images/svg/EmptyCheckBox.svg";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconWhite.svg";
import axios from 'axios';
import PopupComponent from "../../common/Popup/Popup.component";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    checkLocalStorageForLoggedInStatus,
    fetchUserDataByIdAndDispatch,
    loginSuccess,
    setUser
} from "../../../reducers/authSlice";
import ForgotPasswordPopup from "../../common/Popup/ForgotPassword.popup";

const LoginRegisterComponent = (props: {mode: string, changeMode: any, loginSuccess: any, onClose: any}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isConfirmedPasswordValid, setIsConfirmedPasswordValid] = useState(true);
    const [showRegisterSuccessPopup, setShowRegisterSuccessPopup] = useState(false)
    const [showRegisterUnSuccessPopup, setShowRegisterUnSuccessPopup] = useState(false)
    const [useForgotPassword, setUseForgotPassword] = useState<boolean>(false)
    const [successSended, setSuccessSended] = useState<boolean>(false)
    const [errorSended, setErrorSended] = useState<boolean>(false)
    const [loginUnsuccess, setLoginUnsuccess] = useState<string>('')

    const [dialogErrorText, setDialogErrorText] = useState('')
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const navigation = useNavigate()
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [preLogin, setPreLogin] = useState<any>(null)
    const [login, setLogin] = useState('');
    const [isLoginValid, setIsLoginValid] = useState(true);
    const [logged, setLogged] = useState(false);
    const [loginMode, setLoginMode] = useState<string>('none')
    const [verificationCode, setVerificationCode] = useState<string>('')
    const dispatch = useDispatch()
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const checkboxStyles = {
        background: isChecked ? '#d53b5c' : 'transparent',
        border: `2px solid ${!isChecked ? '#d53b5c' : 'transparent'}`,
        width: '20px',
        height: '20px',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: '8px',
    };
    const handleRegistration = () => {
        if (!email || !phone || !name || !password || !repeatPassword) {
            console.error('Please fill in all required fields.');
            return;
        }

        // Form validation: Check if the email is in a valid format
        if (!emailRegex.test(email)) {
            console.error('Please enter a valid email address.');
            setIsEmailValid(false);
            return;
        }

        // Form validation: Check if the name has a minimum length of 2 characters
        if (name.length < 2) {
            console.error('Name must be at least 2 characters long.');
            setIsNameValid(false);
            return;
        }

        // Form validation: Check if the password has a minimum length of 8 characters
        if (password.length < 8) {
            console.error('Password must be at least 8 characters long.');
            setIsPasswordValid(false);
            return;
        }

        // Form validation: Check if the password and confirmed password match
        if (password !== repeatPassword) {
            console.error('Passwords do not match.');
            setIsConfirmedPasswordValid(false);
            return;
        }

        // Reset validation status for all fields if all validations pass
        setIsEmailValid(true);
        setIsPhoneValid(true);
        setIsNameValid(true);
        setIsPasswordValid(true);
        setIsConfirmedPasswordValid(true);

        // Get the form data (you should replace this with actual form data)
        const registrationData = {
            email: email,
            phone: phone,
            name: name,
            password: password,
        };

        // Make the POST request to the registration API endpoint
        axios
            .post('https://gscoworking.com/api/user/register', registrationData)
            .then((response) => {
                // Handle the successful registration response (e.g., show a success message)
                setShowRegisterSuccessPopup(true)
            })
            .catch((error: any) => {
                // Handle registration errors (e.g., show an error message)
                setDialogErrorText(error.response.data.error)
                setShowRegisterUnSuccessPopup(true)
                console.error('Registration failed:', error);
            });
    };
    const handlePhoneChange = (e:any) => {
        const input = e.target.value;
        let formattedPhone = `+357-`;

        // Remove non-digit characters and limit the input to 10 digits
        const cleaned = input.replace(/\D/g, '').substring(0, 10);

        for (let i = 0; i < cleaned.length; i++) {
            if (i === 2 || i === 4) {
                formattedPhone += `-${cleaned[i]}`;
            } else {
                formattedPhone += cleaned[i];
            }
        }

        setMaskedPhone(formattedPhone);
    };
    const formatPhoneNumber = (input: string) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
        if (cleaned.length >= 7) {
            const formatted = `+357-${cleaned.substring(0, 2)}-${cleaned.substring(2, 8)}`;
            return formatted;
        }
        return `+357-${cleaned}`;
    };

    async function handleResendVerification() {
        const loginData = {
            login: login,
            password: password,
        };
        if (!login || !password) {
            console.error('Please fill in all required fields.');
            return;
        }
        // Make the POST request to the login API endpoint
        axios
            .post('https://gscoworking.com/api/user/login', loginData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {})
    }

    const handleLogin = () => {
        // Form validation: Check if required fields are not empty
        if (loginMode !== 'fa2') {
            if (!login || !password) {
                console.error('Please fill in all required fields.');
                return;
            }

            // Prepare the data object to be sent in the request body
            const loginData = {
                login: login,
                password: password,
            };

            // Make the POST request to the login API endpoint
            axios
                .post('https://gscoworking.com/api/user/login', loginData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    // Handle the successful login response
                    if (isChecked) {
                        localStorage.setItem('@cred', JSON.stringify({...loginData, saved: isChecked}))
                    }
                    if (!isChecked) {
                        localStorage.removeItem('@cred')
                    }
                    if (response.data && response.data.user && response.data.user.fa2) {
                        setLoginMode('fa2')
                        setPreLogin(response.data.user)
                        return
                    }
                    try {
                        localStorage.setItem('logged', 'true');
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        dispatch(setUser(response.data.user))
                        dispatch(loginSuccess)
                        // @ts-ignore
                        dispatch(fetchUserDataByIdAndDispatch(response.data.user._id))
                        props.loginSuccess(response.data.user)
                        setSuccessSended(true)
                        setIsLoginSuccessful(true); // Set the login success state to true
                        setLoginError(''); // Reset any previous login errors
                        setShowRegisterSuccessPopup(true)

                    } catch (e: any) {
                        console.log(e.message)
                    }

                    // You can handle the login success here, such as storing the token in local storage or setting the user data in the application state.
                    // For example, if the API returns a token:
                    // localStorage.setItem('token', response.data.token);
                    // If the API returns user data:
                    // setUser(response.data);
                })
                .catch((error) => {
                    // Handle login errors
                    console.error('Login failed:', error);
                    setIsLoginSuccessful(false); // Set the login success state to false
                    setLoginError('Invalid email or password.'); // Set the login error message
                    setLoginUnsuccess(error?.response?.data?.message)

                });
        }
        if (loginMode === 'fa2') {
            const data = {
                code: verificationCode,
                userId: preLogin?._id
            }
            axios
                .post('https://gscoworking.com/api/user/verify', data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    try {
                        localStorage.setItem('logged', 'true');
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        dispatch(setUser(response.data.user))
                        dispatch(loginSuccess)
                        // @ts-ignore
                        dispatch(fetchUserDataByIdAndDispatch(response.data.user._id))
                        props.loginSuccess(response.data.user)
                        setSuccessSended(true)
                        setIsLoginSuccessful(true); // Set the login success state to true
                        setLoginError(''); // Reset any previous login errors
                        setShowRegisterSuccessPopup(true)

                    } catch (e: any) {
                        console.log(e.message)
                        setLoginError('Invalid verification code.'); // Set the login error message
                        setLoginUnsuccess(e?.response?.data?.message)
                    }
                })
                .catch((e:any)=>{
                    console.log(e.message)
                    setLoginError('Invalid verification code.'); // Set the login error message
                    setLoginUnsuccess(e?.response?.data?.message)
                })
        }
    };

    useEffect(() => {
        try {

        } catch (e: any) {

        }
        const isUserLogged = localStorage.getItem('logged');
        setLogged(isUserLogged === 'true');
    }, [isLoginSuccessful]);

    useEffect(() => {
        let cred: any = JSON.parse(localStorage.getItem('@cred') || '{}')
        setLogin(cred.login)
        setPassword(cred.password)
        setIsChecked(cred.saved)
    }, [])

    if (props.mode === 'login' && !logged) {
        return (
            <div className={`${styles.loginContainer} col align-items-center`}>
                <CloseIcon onClick={()=>{props.onClose()}} className={'pointer'} style={{position: 'absolute', top: 20, right: 20}}/>
                {loginMode === 'none' && (
                    <>
                        <span className={styles.loginText}>Log In</span>
                        <input
                            className={`${styles.inputField} mb-15 mt-10 ${(!login || !isLoginValid) ? styles.invalid : ''}`}
                            placeholder={'email / login'}
                            value={login}
                            onChange={(e) => {
                                setLogin(e.target.value);
                                setIsLoginValid(true); // Reset the login validity state when the user starts typing
                            }}
                            onBlur={() => setIsLoginValid(!!login)} // You can add your own validation logic for the login field
                        />
                        <input
                            className={`${styles.inputField} mb-17 ${(!password || !isPasswordValid) ? styles.invalid : ''}`}
                            placeholder={'password'}
                            type={'password'}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setIsPasswordValid(true); // Reset the password validity state when the user starts typing
                            }}
                            onBlur={() => setIsPasswordValid(!!password)} // You can add your own validation logic for the password field
                        />
                    </>
                )}
                {loginMode === 'fa2' && (
                    <div className={'col justify-content-between'} style={{height: '100%'}}>
                        <span className={styles.loginText}>Verification</span>
                        <span className={'text-14'}>Enter the code you recieved on e-mail</span>

                        <input
                            className={`${styles.inputField} mb-15 mt-10 ${(!login || !isLoginValid) ? styles.invalid : ''}`}
                            placeholder={'code from email'}
                            value={verificationCode}
                            onChange={(e) => {
                                setVerificationCode(e.target.value);
                            }}
                        />
                        <div className={'col align-items-center'}>
                            <div className={'red-button row center-content mb-10 pointer'} style={{width: '100%'}} onClick={handleLogin}>
                                <span className={'red-button-text'}>Finish</span>
                            </div>
                            <span className={'pointer blue-text bold'} onClick={handleResendVerification}>Send another code</span>
                        </div>
                    </div>
                )}
                {loginMode !== 'fa2' && (
                    <>
                        <div className={'row align-items-center mb-20 justify-content-between col-24'}>
                            <label className={'row align-items-center pointer'}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    hidden={true}
                                />
                                {isChecked && (
                                    <Checkbox />
                                )}
                                {!isChecked && (
                                    <EmptyCheckbox />
                                )}
                                <span className={`ml-5 ${styles.regular} text-white`}>Remember me</span>
                            </label>
                            <div className={`${styles.iForgotPassword} pointer`} onClick={()=> {
                                setUseForgotPassword(old => !old)
                                document.body.classList.add('popup-open');
                            }}>I forgot password</div>
                        </div>
                        <div className={'red-button row center-content mb-10 pointer'} style={{width: '100%'}} onClick={handleLogin}>
                            <span className={'red-button-text'}>Log in</span>
                        </div>
                        <span className={`${styles.signUpLink} pointer`} onClick={()=>props.changeMode('register')}>Sign up</span>

                    </>
                )}

                {useForgotPassword && (
                    <ForgotPasswordPopup error={()=>setErrorSended(true)} success={()=>setSuccessSended(true)} onClose={()=> {
                        document.body.classList.remove('popup-open');
                        setUseForgotPassword(old => !old)
                    }} />
                )}
                {successSended && (
                    <PopupComponent title={'Success'} text={'Email with link for password recovery was sent success'} onClose={()=>setSuccessSended(false)} buttonText1={'Ok'} onButton1Click={()=>setSuccessSended(false)} />
                )}
                {errorSended && (
                    <PopupComponent title={'Error'} text={'Error occurred during password recovery. Check provided data and try again'} onClose={()=>setErrorSended(false)} buttonText1={'Ok'} onButton1Click={()=>setErrorSended(false)} />
                )}
                {loginUnsuccess && (
                    <PopupComponent title={'Error'} text={loginUnsuccess} onClose={()=>setLoginUnsuccess('')} onButton1Click={()=>setLoginUnsuccess('')} buttonText1={'Ok'} />
                )}
            </div>
        )
    } else if (props.mode === 'register' && !logged) {
        return (
            <div className={`${styles.registerContainer} col align-items-center`}>
                <span className={styles.loginText}>Register</span>
                <input
                    className={`${styles.inputField} mb-15 mt-10 ${!isEmailValid ? styles.invalid : ''}`}
                    placeholder={'email'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => setIsEmailValid(!!emailRegex.test(email))}
                />

                <InputMask
                    mask="+99999999999"
                    className={`${styles.inputField} mb-17 ${!isPhoneValid ? styles.invalid : ''}`}
                    maskChar="_"
                    value={phone}
                    placeholder={'phone number'}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={() => setIsPhoneValid(!!maskedPhone)}
                >
                </InputMask>
                <input
                    className={`${styles.inputField} mb-17 ${!isNameValid ? styles.invalid : ''}`}
                    placeholder={'your name'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => setIsNameValid(name.length >= 2)}
                />
                <input
                    className={`${styles.inputField} mb-17 ${!isPasswordValid ? styles.invalid : ''}`}
                    placeholder={'create password'}
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => setIsPasswordValid(password.length >= 8)}
                />
                <input
                    className={`${styles.inputField} mb-17 ${!isConfirmedPasswordValid ? styles.invalid : ''}`}
                    placeholder={'repeat password'}
                    type={'password'}
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    onBlur={() => setIsConfirmedPasswordValid(password === repeatPassword)}
                />
                <div className={'red-button row center-content mt-20 mb-10 pointer'} style={{width: '100%'}} onClick={handleRegistration}>
                    <span className={'red-button-text'}>Sign up</span>
                </div>
                <span className={`${styles.signUpLink} pointer`}  onClick={()=>props.changeMode('login')}>Log in</span>
                {showRegisterSuccessPopup && (
                    <PopupComponent title={'Thank you for your registration'} text={'We send you a letter with your login and password to the e-mail you’ve left'} onClose={()=> {
                        window.location.reload()
                    }} />
                )}
                {showRegisterUnSuccessPopup && (
                <PopupComponent title={'Something went wrong try again'} text={`${dialogErrorText} - Check the data and try again`} onClose={()=>setShowRegisterUnSuccessPopup(false)} />
                )}

            </div>
        )
    } else {
        return <></>
    }
};

export default LoginRegisterComponent;
export {};
