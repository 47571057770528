import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styles from './PrivateInfoWindow.module.scss'
import RoomCardComponent from "../SpotsPage/RoomCard/RoomCard.component";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {checkLocalStorageForLoggedInStatus, fetchUserDataByIdAndDispatch} from "../../../reducers/authSlice";
import {ReactComponent as NotSelected} from "../../../assets/images/svg/CheckboxNotSelected.svg";
import {ReactComponent as Selected} from "../../../assets/images/svg/CheckboxSelected.svg";
import {ReactComponent as MasterCard} from "../../../assets/images/svg/MasterCars.svg";
import {ReactComponent as Visa} from "../../../assets/images/svg/Visa.svg";
import {ReactComponent as AddNewCard} from "../../../assets/images/svg/AddCard.svg";
import {ReactComponent as ChevroneRight} from "../../../assets/images/svg/ChevronRight.svg";
import FavoritesOnMapComponent from "./FavoritesOnMap.component";
import {fetchOrdersAsync, setFutureOrders} from "../../../reducers/orderSlice";
import ActiveBookingComponent from "../../common/ActiveBooking/ActiveBooking.component";
import MessageComponent from "../../common/Notifications/Message.component";
import NotificationsComponent from "../../common/Notifications/Notifications.component";
import SettingsPage from "../../../pages/Settings.page";
import ReviewsComponent from "../../common/Reviews/Reviews.component";
import InputMask from "react-input-mask";
import MyFundsComponent from "../funds/MyFunds.component";
import {ReactComponent as CopyIcon} from "../../../assets/images/svg/CopyWhite.svg";


const PrivateInfoWindowComponent = (props:{selectedMenu: number, userId: string, setMenu: any}) => {
    const dispatch = useDispatch()
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);
    const futureOrdersFromStore = useSelector((state: RootState) => state.orders.futureOrders);
    const allOrdersFromStore = useSelector((state: RootState) => state.orders.allOrders);
    const prevOrdersFromStore = useSelector((state: RootState) => state.orders.prevOrders)
    const initialCardNumber = '____ ____ ____ ____'; // Use underscores (_) as placeholders
    const [mapShow, setMapShow] = useState(false)
    const [userObject, setUserObject] = useState<any>(null)
    const [orders, setOrders] = useState<any>(null)
    const [prevOrders, setPrevOrders] = useState<any>(null)
    const [showAllCards, setShowAllCards] = useState<boolean>(false)
    const [displayedCards, setDisplayedCard] = useState<any[]>([])
    const [displayAllOrders, setDisplayAllOrders] = useState<boolean>(false)
    const [fieldsDisabled, setFieldsDisabled] = useState({
        name: true,
        lastName: true,
        phone: true,
        dateOfBirth: true
    })
    const [editPayment, setEditPayment] = useState(false)
    const [addPayment, setAddPayment] = useState(false)
    const [certificate, setCertificate] = useState<string>('')
    const [certificateMessage, setCertificateMessage] = useState<any>({
        status: 'success',
        message: ''
    })
    const [referal, setReferal] = useState<any>(null)
    const [newBind, setNewBind] = useState({
        cardType: '',
        cardNumber: initialCardNumber,
        cardHolder: '',
        cardCVC: '',
        expirationDate: '',
        mainCard: false
    })

    async function copyToClipboard(code: string) {
        try {
            await navigator.clipboard.writeText(code);
        } catch (error) {
            console.error('Не удалось скопировать текст: ', error);
        }
    }

   async function handleUserUpdate () {
       try {
           // Prepare the updated user data
           const updatedUserData = {
               name: userObject.name,
               lastName: userObject.lastName,
               phone: userObject.phone,
               dateOfBirth: userObject.dateOfBirth
               // Include other fields you want to update
           };

           // Make the API request to update the user
           const response = await axios.put(`https://gscoworking.com/api/user/${props.userId}`, updatedUserData);
           setFieldsDisabled({
               name: true,
               lastName: true,
               phone: true,
               dateOfBirth: true
           })
           // If the update is successful, update the state with the updated user data
           // @ts-ignore
           dispatch(checkLocalStorageForLoggedInStatus())
       } catch (error) {
           console.error('Error updating user:', error);
           // Handle the error (show an error message, etc.)
       }
    }
    useEffect(() => {
        if (fromStore) {
            setUserObject(fromStore);
            if (fromStore.referalCode) {
                axios.get('https://gscoworking.com/api/promo-codes/get-code/' + fromStore.referalCode).then((r: any) => {
                    setReferal(r.data)
                })
            }
        }
    }, [fromStore]);

    useEffect(()=>{
        if (userObject && userObject.paymentMethods) {
            let cards = userObject.paymentMethods.filter((x:any)=>x.mainCard)
            if (showAllCards) {
                setDisplayedCard(userObject.paymentMethods)
            } else {
                setDisplayedCard([cards[0]])
            }
        }
    }, [showAllCards, userObject])

    useEffect(() => {
        if (allOrdersFromStore && displayAllOrders) {
            setOrders(allOrdersFromStore);
        }
        if (futureOrdersFromStore && !displayAllOrders) {
            setOrders(futureOrdersFromStore);
        }
        setPrevOrders(prevOrdersFromStore)
    }, [futureOrdersFromStore, allOrdersFromStore, displayAllOrders]); // Следим за изменениями в 'fromStore'
    useEffect(() => {
        // Диспатчим действие для проверки статуса входа в систему и загрузки данных пользователя с сервера
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        // @ts-ignore
    }, [dispatch]);

    async function setMainCard(selectedCardNumber: string) {
        const updatedData = userObject.paymentMethods.map((card: any) => ({
            ...card,
            mainCard: card.cardNumber === selectedCardNumber,
        }));
        setUserObject((old: any)=>({...old, paymentMethods: updatedData}))
        const response = await axios.put(`https://gscoworking.com/api/user/${userObject._id}`, {paymentMethods: updatedData});

        // If the update is successful, update the state with the updated user data
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus())
    }
    const formatCardNumber = (value: string) => {
        // Remove any non-digit characters from the input
        const digitsOnly = value.replace(/\D/g, '');

        // Use Array.from() to loop through each character and replace underscores with digits
        const formattedValue = Array.from(initialCardNumber).map((char, index) =>
            digitsOnly[index] || char === '_' ? digitsOnly[index] || char : '_'
        );

        return formattedValue.join('');
    };
    const handleInputChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;

        // Remove any non-digit characters from the input
        const digitsOnly = value.replace(/\D/g, '');

        // Ensure the number is limited to a maximum of 16 digits
        const truncatedDigits = digitsOnly.slice(0, 16);

        // Split the digits into groups of four
        const groups = truncatedDigits.match(/(\d{1,4})/g);

        // Join the groups with spaces to create the formatted value
        const formattedValue = groups ? groups.join(' ') : '';
        setNewBind(old=>({...old, cardNumber: formattedValue}));
    };

    const handleExpirationInputChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;

        // Remove any non-digit characters from the input
        const digitsOnly = value.replace(/\D/g, '');

        // Ensure the number is limited to a maximum of 6 digits (MMYYYY)
        const truncatedDigits = digitsOnly.slice(0, 6);

        // Split the digits into groups of two for MM / YYYY format
        const groups = truncatedDigits.match(/^(\d{1,2})(\d{0,4})/);

        // Format the expiration date as "MM / YYYY"
        const formattedValue = groups ? `${groups[1]} / ${groups[2]}` : '';

        setNewBind(old=>({...old, expirationDate: formattedValue}));
    };

    const getCardTypeByNumber = (cardNumber: string) => {
        const cardTypes = [
            { type: 'Visa', pattern: /^4/ },
            { type: 'MasterCard', pattern: /^5[1-5]/ },
            { type: 'American Express', pattern: /^(34|37)/ },
            { type: 'Discover', pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|2[0-5][0-9])|64[4-9]|65)/ },
            { type: 'Diners Club - Carte Blanche', pattern: /^(30[0-5]|3095)/ },
            { type: 'Diners Club - International', pattern: /^(36|38)/ },
            { type: 'Diners Club - USA & Canada', pattern: /^(54|55)/ },
            { type: 'JCB', pattern: /^35/ },
            { type: 'UnionPay', pattern: /^62/ },
            { type: 'MIR', pattern: /^2200/ },
        ];

        const sanitizedNumber = cardNumber.replace(/\D/g, ''); // Remove non-digit characters

        for (const cardType of cardTypes) {
            if (sanitizedNumber.match(cardType.pattern)) {
                return cardType.type;
            }
        }

        return 'Unknown'; // If the card type is not recognized
    };

    async function bindNewCard() {
        const newCard = {
            cardNumber: newBind.cardNumber.slice(-4),
            cardType: getCardTypeByNumber(newBind.cardNumber),
            mainCard: false
        }
        const updatedData = [...userObject.paymentMethods, newCard];
        const response = await axios.put(`https://gscoworking.com/api/user/${userObject._id}`, {paymentMethods: updatedData});
        setEditPayment(false)
        setAddPayment(false)
        setNewBind({
            cardType: '',
            cardNumber: initialCardNumber,
            cardHolder: '',
            cardCVC: '',
            expirationDate: '',
            mainCard: false
        })
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus())
    }

    function handleOrderChange() {
        // @ts-ignore
        dispatch(fetchOrdersAsync(fromStore._id))
    }

    const handleMapClose = () => {
        setMapShow(false)
    }

    function formatDateToDDMMYYYY(date: any): string {
        date = new Date(date)
        const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zeros if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based index) and add 1, then pad with leading zeros if needed
        const year = date.getFullYear().toString(); // Get the full year

        return `${day}.${month}.${year}`;
    }

    async function applyCertificate() {
        let answer = await axios.post('https://gscoworking.com/api/cert/apply', {code: certificate, userId: userObject._id}).catch((e:any)=>{
            console.log(e.message)
            setCertificateMessage({status: 'error', message: `Can't apply certificate`})
        })
        if (answer && answer.data) {
            if (answer.data.success)  {
                setCertificateMessage(
                    {message: `${answer.data.message.amount}€ certificate has been successfully activated`, status: 'success'})
                // @ts-ignore
                dispatch(fetchUserDataByIdAndDispatch(userObject._id));
            } else {
                setCertificateMessage({status: 'error', message: `Can't apply certificate`})
            }
        }
    }

    return (
       <>
           {props.selectedMenu !==0 && props.selectedMenu !==1 && props.selectedMenu !==2 && (
               <></>
           )}
           {props.selectedMenu===0 && userObject && (
               <div className={'row col-12 mt-50 gap-90'}>
                   <div className={'col col-11 gap-25'}>
                       <span className={'spot-title'}>Personal information:</span>
                       <div className={'col col-12 gap-10'}>
                           <div className={'booking-input-wrapper col'} >
                               <span>Name</span>
                               <div className={'row align-items-center'}>
                                   <input
                                       className={'booking-input col-22'}
                                       disabled={fieldsDisabled.name}
                                       value={userObject.name}
                                       onChange={(e) => setUserObject((old: any)=>({...old, name: e.target.value}))}
                                   />
                                   {fieldsDisabled.name && (
                                       <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, name: false}))}>Edit</span>
                                   )}
                               </div>
                           </div>
                           <div className={'booking-input-wrapper col'} >
                               <span>Last name</span>
                               <div className={'row align-items-center'}>
                                   <input
                                       className={'booking-input  col-22'}
                                       value={userObject.lastName}
                                       disabled={fieldsDisabled.lastName}
                                       onChange={(e) => setUserObject((old: any)=>({...old, lastName: e.target.value}))}
                                   />
                                   {fieldsDisabled.lastName && (
                                       <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, lastName: false}))}>Edit</span>
                                   )}
                               </div>
                           </div>
                           <div className={'booking-input-wrapper col'} >
                               <span>Phone</span>
                               <div className={'row align-items-center'}>

                                   <InputMask
                                       mask="+99999999999"
                                       disabled={fieldsDisabled.phone}
                                       className={'booking-input col-22'}
                                       maskChar="_"
                                       value={userObject.phone}
                                       placeholder={'phone number'}
                                       onChange={(e) => setUserObject((old: any)=>({...old, phone: e.target.value}))}
                                   >
                                   </InputMask>
                                   {fieldsDisabled.phone && (
                                       <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, phone: false}))}>Edit</span>
                                   )}
                               </div>
                           </div>
                           <div className={'booking-input-wrapper col'} >
                               <span>E-mail</span>
                               <input className={'booking-input'} value={userObject.email} disabled={true}/>
                           </div>
                           <div className={'booking-input-wrapper col'} >
                               <span>Date of Birth</span>
                               <div className={'row align-items-center'}>
                                    <input
                                        type={'date'}
                                        className={'booking-input col-22'}
                                        value={userObject.dateOfBirth ? new Date(userObject.dateOfBirth).toISOString().slice(0, 10) : ''}
                                        disabled={fieldsDisabled.dateOfBirth}
                                        onChange={(e) => setUserObject((old: any)=>({...old, dateOfBirth: e.target.value}))}
                                    />
                                   {fieldsDisabled.dateOfBirth && (
                                       <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, dateOfBirth: false}))}>Edit</span>
                                   )}
                               </div>

                           </div>
                       </div>
                       <div className={'red-button row align-content-center justify-content-center mt-70 pointer'} onClick={handleUserUpdate}>
                           <span className={'red-button-text'}>Save changes</span>
                       </div>
                   </div>
                   <div className={'col col-10'}>
                       <div className={'col gap-60'}>
                           <div className={'col gap-10'}>
                               <div className={'row align-items-center justify-content-between mb-21'}>
                                   <span className={'spot-title'}>Balance:</span>
                                   {/*<span className={`pointer col-2 ${styles['edit-text']}`}>Edit</span>*/}
                               </div>
                               {userObject && userObject.fa2 && (
                                   <>
                                       <span className={'text-price text-26 bold sea-text'}>{Math.floor(userObject?.deposit?.totalAmount) || 0} €</span>
                                       <div className={'red-button mt-20 row align-items-center justify-content-center'} style={{border: 'none'}} onClick={()=>props.setMenu(1)}>
                                           <span className={'red-button-text'}>Add funds</span>
                                       </div>
                                   </>
                               )}
                               {!userObject || !userObject.fa2 && (
                                   <span className={'text-14 blue-text pointer'} onClick={()=>props.setMenu(6)}>Please turn on two-factor authorization</span>
                               )}
                           </div>

                           {userObject && userObject.discount && (
                               <div className={styles['discount-container']} style={{backgroundImage: `url(${require('../../../assets/images/discountBlockBackground.png')})`, backgroundPosition: 'right', backgroundRepeat: 'no-repeat'}}>
                                   <span className={'text-16 sea-text'}>My discount</span>
                                   <span className={'text-38 sea-text bold'}>{userObject.discount}%</span>
                               </div>
                           )}
                           <div className={'col gap-10'}>
                               <span className={`spot-title`}>My promocode:</span>
                               <div className={`mt-20 relative ${styles.promo}`}>
                                   <CopyIcon className={`${styles.copyButton}`}
                                             onClick={() => copyToClipboard(userObject?.referalCode)}/>
                                   <span className={`${styles.promocode}`}>{userObject?.referalCode}</span>
                               </div>
                               {referal && (
                                   <span className={'sea-text'}>Your referral code: {referal.discount.profit.value}% cashback for you, {referal.discount.value}% off for
                                    others</span>
                               )}
                           </div>
                           <div className={'col gap-10'}>
                               <span className={'spot-title'}>Activate certificate:</span>
                               <div className={`relative row align-items-center justify-content-between ${styles.activateCertificate}`}>
                                   <input className={styles.amountInput} value={certificate} onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                       let input = e.target.value.replace(/[^A-Za-z0-9]/g, ''); // Allow letters and digits
                                       let formattedCertificate = '';

                                       for (let i = 0; i < input.length; i++) {
                                           if (i === 4 || i === 8) {
                                               formattedCertificate += '-';
                                           }
                                           formattedCertificate += input[i];
                                       }

                                       if (formattedCertificate.length > 13) {
                                           // Truncate to "XXXX-XXXX-XXXX"
                                           formattedCertificate = formattedCertificate.slice(0, 14);
                                       }
                                       setCertificate(formattedCertificate.toUpperCase())}} />
                                   <span className={`pointer ${styles.applyBtn}`} onClick={applyCertificate}>Apply</span>
                               </div>
                               {certificateMessage && certificateMessage.status==='success' && (
                                   <span className={'text-14 sea-text'}>{certificateMessage.message}</span>
                               )}
                               {certificateMessage && certificateMessage.status==='error' && (
                                   <span className={'text-14 magenta-text'}>{certificateMessage.message}</span>
                               )}
                           </div>
                       </div>
                   </div>

               </div>
           )}
           {props.selectedMenu===1 && userObject && (
               <MyFundsComponent />
           )}
           {props.selectedMenu===2 && userObject && (
               <div className={'row justify-content-start'}>
                   <div className={'col col-16 mt-50 gap-25 wrap pb-100'}>
                       <span className={'spot-title'}>Favorite spots:</span>
                       <span style={{width: '90%'}}>The rooms you marked as favorites are saved here. By clicking on the heart you can save or delete rooms

</span>
                       {!mapShow && (
                           <div className={'row wrap mt-50 gap-25'}>
                                {userObject.favoriteRooms.map((room: any)=>(
                                    <RoomCardComponent room={room} />
                                ))}
                           </div>
                       )}
                       {mapShow && (
                           <FavoritesOnMapComponent openPopup={false} favorites={userObject.favoriteRooms} closeClick={handleMapClose} width={'660px'} height={'660px'} />
                       )}
                   </div>
                   <div className={'row align-items-center justify-content-center mt-60'} style={{backgroundImage: `url(${require('../../../assets/images/ShowOnMapBackground.png')})`, backgroundPosition: 'center', minWidth: 290, height: 100, borderRadius: 8}}>
                       {!mapShow && (<div className={'red-button row align-content-center justify-content-center pointer'}
                             style={{width: 154}} onClick={() => setMapShow(true)}>
                           <span className={'red-button-text'}>Show on map</span>
                       </div>)}
                       {mapShow && (<div className={'sea-button row align-content-center justify-content-center pointer'}
                             style={{width: 154}} onClick={() => setMapShow(false)}>
                           <span className={'red-button-text'}>Show cards</span>
                       </div>)}
                   </div>
               </div>
           )}
           {props.selectedMenu === 3 && orders && orders.length>0 && (
               <div className={'col gap-30 mt-50 col-12 '}>
                   <div className={'row align-items-center justify-content-between'}>
                       <span className={'spot-title'}>{displayAllOrders ? 'All' : 'Active'} Bookings:</span>
                       {/*{!displayAllOrders && (*/}
                       {/*    <span className={'blue-text pointer'} onClick={()=>setDisplayAllOrders(true)}>All bookings</span>*/}
                       {/*)}*/}
                       {/*{displayAllOrders && (*/}
                       {/*    <span className={'blue-text pointer'} onClick={()=>setDisplayAllOrders(false)}>Active bookings</span>*/}
                       {/*)}*/}
                   </div>
                   {futureOrdersFromStore.map((order: any, idx: number)=>(
                       <div className={'mb-70'}>
                           <ActiveBookingComponent canceled={order.status === 'Canceled'} width={12} withDeletion={true} amount={order.typeOfPayment === 'money' ? order.paymentIntent.amount/100 : order.amount} date={formatDateToDDMMYYYY(order.slots[0].date)} slot={order.slots} address={order.buildingID.address} keyCode={order.keyCode}  withPrice={true} id={order._id} handleOrdersChange={handleOrderChange} title={order.roomID.title} order={order}/>
                       </div>
                   )) }
                   <div className={'row align-items-center justify-content-between'}>
                       <span className={'spot-title'}> Previous bookings:</span>
                       {/*{!displayAllOrders && (*/}
                       {/*    <span className={'blue-text pointer'} onClick={()=>setDisplayAllOrders(true)}>All bookings</span>*/}
                       {/*)}*/}
                       {/*{displayAllOrders && (*/}
                       {/*    <span className={'blue-text pointer'} onClick={()=>setDisplayAllOrders(false)}>Active bookings</span>*/}
                       {/*)}*/}
                   </div>
                   {prevOrdersFromStore.map((order: any, idx: number)=>(
                       <div className={'mb-70'}>
                           <ActiveBookingComponent readonly={true} canceled={order.status === 'Canceled'} width={12} withDeletion={true} amount={order.amount} date={new Date(order.slots[0].date).toDateString()} slot={order.slots} address={order.buildingID.address} keyCode={order.keyCode}  withPrice={true} id={order._id} handleOrdersChange={handleOrderChange} title={order.roomID.title}/>
                       </div>
                   )) }
               </div>
           )}
           {props.selectedMenu === 4 && userObject && (
               <NotificationsComponent user={userObject._id} notifications={userObject.notifications}/>
           )}
           {props.selectedMenu === 5 && userObject && (
               <div className={'col gap-30 mt-50 col-10'}>
                   <span className={'spot-title'}>My reviews:</span>
                   <ReviewsComponent user={userObject._id} />
               </div>
           )}
           {props.selectedMenu === 6 && userObject && (
               <SettingsPage />
           )}

       </>
    );
};

export default PrivateInfoWindowComponent;
export {};
