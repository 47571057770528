import React, {useEffect, useState} from 'react';
import styles from './Teachers.module.scss'
import PageTitleComponent from "../../common/PageTitle/PageTitle.component";
import axios from "axios";
import RoomCardComponent from "./TeacherCard/RoomCard.component";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconWhite.svg";
import InputMask from "react-input-mask";
import TopBar from "../TopBar/TopBar";

const TeachersPageComponent = () => {
    const [teachers, setTeachers] = useState<any[]>([])
    const [showTeacherForm, setShowTeacherForm] = useState<boolean>(false)
    const [currentTeacher, setCurrentTeacher] = useState<any>(null)
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [booked, setBooked] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('');

    function handleContactButton(t: any) {
        setCurrentTeacher(t)
        setShowTeacherForm(true)
    }

    function bookTutor() {
        if (!currentTeacher) {
            return
        }
        let data: any = {
            name,
            phone,
            message,
            teacher: currentTeacher._id,
            date: new Date()
        }
        axios.post(`https://gscoworking.com/api/teachers/book/${currentTeacher._id}`, data).then((answer: any)=>{
            setBooked(true)
        })
    }

    useEffect(()=>{
        axios.get('https://gscoworking.com/api/teachers/get-all').then((answer: any)=>{
            setTeachers(answer.data)
        })
    }, [])
    return (
        <>
            <TopBar backLink={'/'} MiddlePart={'Partners'}/>
            <div className={`wrapper col ${styles.mobile}`}>
                <span className={`mt-1 mb-15 ${styles.caption}`}>You can choose any teacher you like. He will help you to reach better results
in your music skills quiker</span>
                <div className={'row wrap gap-25 mt-25'}>
                    {teachers.map((teacher: any)=>(
                        <RoomCardComponent room={teacher}  handlePress={handleContactButton}/>
                    ))}
                </div>
                {showTeacherForm && currentTeacher && (
                    <div className={styles['popup-overlay']}>
                        <div className={`pointer relative ${styles['popup-container']}`}>
                            <div className={styles['popup-header']}>
                                <span className={'text-price text-align-center col-24'}>Get in touch</span>
                            </div>
                            <CloseIcon className={styles['close-icon']} onClick={()=>{
                                setShowTeacherForm(false)
                                setCurrentTeacher(null)
                            }} />
                            <div className={styles['popup-content']}>
                                <p>{'Connect with a Teacher for Your Lessons'}</p>
                                <input
                                    className={`${styles.inputField} mb-17 ${!isNameValid ? styles.invalid : ''}`}
                                    placeholder={'your name'}
                                    value={name}
                                    disabled={booked}
                                    onChange={(e) => setName(e.target.value)}
                                    onBlur={() => setIsNameValid(name.length >= 2)}
                                />
                                <InputMask
                                    mask="+357 9 999 99 99"
                                    className={`${styles.inputField} mb-17 ${!isPhoneValid ? styles.invalid : ''}`}
                                    maskChar="_"
                                    disabled={booked}
                                    value={phone}
                                    placeholder={'phone number'}
                                    onChange={(e) => setPhone(e.target.value)}
                                    onBlur={() => setIsPhoneValid(!!maskedPhone)}
                                >
                                </InputMask>
                                <textarea
                                    rows={3}
                                    className={`${styles.textareaField} mb-17 ${!isNameValid ? styles.invalid : ''}`}
                                    placeholder={'your message'}
                                    value={message}
                                    disabled={booked}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onBlur={() => setIsNameValid(message.length >= 2)}
                                />
                            </div>
                            <div className={styles['popup-footer'] }>
                                {booked && (
                                    <span className={'magenta-text'}>Success booked</span>
                                )}
                                {!booked && (
                                    <div className={styles.buttonOne} style={{fontWeight: 700}} onClick={()=>{
                                        bookTutor()
                                    }}>
                                        Submit Request
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
};

export default TeachersPageComponent;
