import {AnyAction, createSelector, createSlice, Dispatch, PayloadAction, ThunkDispatch} from '@reduxjs/toolkit';
import { RootState } from './types';
import axios from 'axios';
import {selectAllBuildings} from "./buildingSlice";

export interface AuthState {
    isLoggedIn: boolean;
    user: any;
    userFull: any;
}

const initialState: AuthState = {
    isLoggedIn: false,
    user: null,
    userFull: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state) => {
            state.isLoggedIn = true;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.userFull = null;
            try {
                localStorage.removeItem('user')
                localStorage.removeItem('logged')
            } catch (e: any) {
                console.log(e.message)
            }
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserFull: (state, action) => {
            state.userFull = action.payload;
        },
        addToFavoritesSuccess: (state, action: PayloadAction<string>) => {
            if (state.userFull) {
                state.userFull.favoriteRooms.push(action.payload);
            }
        },
        removeFromFavoritesSuccess: (state, action: PayloadAction<string>) => {
            if (state.userFull) {
                state.userFull.favoriteRooms = state.userFull.favoriteRooms.filter((roomId: string) => roomId !== action.payload);
            }
        },
    },
});

export const {
    loginSuccess,
    logout,
    setUser,
    setUserFull,
    addToFavoritesSuccess,
    removeFromFavoritesSuccess,
} = authSlice.actions;

// Thunk action creator to add a room to favorites
export const addToFavorites = (userId: string, roomId: string) => async (
    dispatch: ThunkDispatch<AuthState, any, AnyAction>
) => {
    try {
        await axios.post(`/api/user/${userId}/favorites/${roomId}`);
        dispatch(addToFavoritesSuccess(roomId));

        // Fetch the updated user's data with populated favorites after success
        axios.get(`/api/user/${userId}`).then((answer) => {
            dispatch(setUserFull(answer.data));
        });
    } catch (error) {
        console.error('Error adding room to favorites:', error);
    }
};

// Thunk action creator to remove a room from favorites
export const removeFromFavorites = (userId: string, roomId: string) => async (
    dispatch: ThunkDispatch<AuthState, any, AnyAction>
) => {
    try {
        await axios.delete(`https://gscoworking.com/api/user/${userId}/favorites/${roomId}`);
        dispatch(removeFromFavoritesSuccess(roomId));

        // Fetch the updated user's data with populated favorites after success
        axios.get(`https://gscoworking.com/api/user/${userId}`).then((answer) => {
            dispatch(setUserFull(answer.data));
        });
    } catch (error) {
        console.error('Error removing room from favorites:', error);
    }
};

export const fetchUserDataByIdAndDispatch = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await axios.get(`https://gscoworking.com/api/user/${id}`);
        dispatch(setUserFull(response.data));
        if (response.data._id && response.data.fa2) {
            const deposit = await axios.get(`https://gscoworking.com/api/deposit/amount/${response.data._id}`)
            dispatch(setUserFull({...response.data, deposit: deposit.data || 0}))
        }
    } catch (error) {
        dispatch(logout()); // Logout in case of error
    }
};


export const checkLocalStorageForLoggedInStatus = () => async (dispatch: Dispatch<any>) => {
    const isLoggedIn = localStorage.getItem('logged');
    const userString = localStorage.getItem('user');

    if (isLoggedIn === 'true' && userString) {
        dispatch(loginSuccess());
        const usr = JSON.parse(userString);
        dispatch(setUser(usr));

        try {
            const answer = await axios.get(`https://gscoworking.com/api/user/${usr._id}`);
            let userFullData = answer.data;

            if (answer.data._id && answer.data.fa2) {
                try {
                    const depositResponse = await axios.get(`https://gscoworking.com/api/deposit/amount/${answer.data._id}`);
                    userFullData = { ...userFullData, deposit: depositResponse.data || 0 };
                } catch (depositError) {
                    console.error('Error fetching deposit:', depositError);
                    // Обработка ошибок запроса на получение информации о депозите
                }
            }

            dispatch(setUserFull(userFullData));
        } catch (error) {
            console.error('Error fetching user data:', error);
            dispatch(logout());
        }
    } else {
        dispatch(logout());
    }
};

export default authSlice.reducer;
