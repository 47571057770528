import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import socket from "../socket";

const RouteChangeTracker: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname!=='/confirmation' && location.pathname !== '/payment') {
            socket.emit('clear by id')
        }
    }, [location]);

    return null;
};

export default RouteChangeTracker;
