import React, {useState} from 'react';
import styles from './RightColumn.module.scss';
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/CloseIconOutline.svg';
import DraftComponent from "../../common/Draft/Draft.component";
import BookingWidgetComponent from "../../common/BookingWidget/BookingWidget.component";
import BookingWidgetTestComponent from "../../common/BookingWidget/BookingWidgetTest.component";
import {useDispatch, useSelector} from 'react-redux';
import { Slot, saveBookedSlots } from '../../../reducers/bookingSlice';
import {useNavigate} from "react-router-dom";
import {RootState} from "../../../reducers/types";
import axios from "axios";
import PopupComponent from "../../common/Popup/Popup.component";

const RightColumnComponent = (props: {draft: any, currentRoom: any, currentBuilding: any}) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const navigation = useNavigate()
    const [showPopup, setShowPopup] = React.useState(false);
    const [showPopupText, setShowPopupText] = React.useState('');
    function handleBookWidgetCallback(data: any, amount: any) {
        let payload = {
            roomID: props.currentRoom.id,
            buildingID: props.currentBuilding.id,
            date: new Date(),
            slots: data,
            userID: user?._id,
            amount: amount
        }

        let showAlert = false; // Переменная для отслеживания активации alert
        if (props.currentRoom.hasOwnProperty('schedule') && props.currentRoom.schedule["1"]) {
            const limitations = props.currentRoom.schedule["1"].limitations;
            if (limitations) {
                const { start, end, days } = limitations;

                // Проверяем каждый забронированный слот
                data.forEach((slot: any) => {
                    const slotDate = new Date(slot.date);
                    const dayOfWeek = slotDate.getDay();
                    const slotStart = slot.start;
                    const slotEnd = slot.end;
                    const slotDuration = slotEnd - slotStart;
                    // Проверяем соответствие длительности слота и диапазона времени
                        if (!(slotStart >= start && slotEnd <= end)) {
                            // Если слот в допустимом диапазоне и его длительность 1 час
                            if (slotDuration === 1) {
                                setShowPopupText(`Minimum booking of 2 consecutive hours required after ${end}:00`);
                                showAlert = true
                                setShowPopup(true)
                                return
                            }
                        }
                });
            }
        } else {
            console.log("Расписание для текущей комнаты не задано.");
        }
        if (!showAlert) { // Запускаем следующие операции только если предупреждение не было показано
            axios.post('https://gscoworking.com/api/orders/check-slots', {...payload}).then((answer)=>{
            });

            dispatch(saveBookedSlots(payload));
            navigation('/confirmation');
        }
    }

    function formatCurrentDate(date: Date) {
        const currentDate = date;
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        return `${day}.${month}.${year}`;
    }

    return (
        <div className={'col-12 col mt-50 gap-100 align-items-end '}>
           <DraftComponent current={props.currentRoom?._id} id={props.draft} />
            <BookingWidgetTestComponent   callback={handleBookWidgetCallback} date={formatCurrentDate(new Date())} hours={1} room={props.currentRoom}/>
            {showPopup && (
                <PopupComponent buttonText1={'Ok'} onButton1Click={()=>setShowPopup(false)} title={'Incorrect booking'} text={showPopupText} onClose={()=>setShowPopup(false)} />
            )}
           {/*<BookingWidgetComponent date={'28.07.2023'} hours={1} callback={handleBookWidgetCallback}  price={props.currentRoom.price} oldPrice={props.currentRoom.oldPrice}/>*/}
        </div>
    );
};

export default RightColumnComponent;
export {};
