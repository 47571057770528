import React, {useEffect, useState} from 'react';
import axios from "axios";
import {ReactComponent as Dots} from '../../../assets/images/svg/HDots.svg';
import {ReactComponent as RatingStar} from '../../../assets/images/svg/RedStar.svg';
import {ReactComponent as EmptyRatingStar} from '../../../assets/images/svg/EmptyStar.svg';
import styles from './Reviews.module.scss'
import PopupEditReviewComponent from "../Popup/PopupEditReview.component";
import PopupComponent from "../Popup/Popup.component";

interface ReviewsPropsInterface {
    user: string;
}



const Review = (props: {review: any, callback: any}) => {
    const [showMore, setShowMore] = useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false)
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
    const renderStars = () => {
        const maxStars = 5; // Maximum number of stars
        const rating = Math.round(props.review?.reviews?.rating); // Round the rating to the nearest whole number

        const filledStars = [];
        for (let i = 0; i < rating; i++) {
            filledStars.push(<RatingStar key={i} />);
        }

        const emptyStars = [];
        for (let i = 0; i < maxStars - rating; i++) {
            emptyStars.push(<EmptyRatingStar key={rating + i} />);
        }

        return [...filledStars, ...emptyStars];
    };

    async function handleReviewUpdate(data: any) {
        const updateData = await axios.put(`https://gscoworking.com/api/reviews/${props.review?.reviews?._id}`, data)
        if (!updateData) {
            console.log('oops')
        }
        setShowEditPopup(false)
        setShowMenu(false)
        props.callback()
    }

    async function handleReviewDelete() {
        await axios.delete(`https://gscoworking.com/api/reviews/${props.review?.reviews?._id}`)
        setShowMenu(false)
        setShowDeletePopup(false)
        props.callback()
    }

  return (
      <div className={'col col-15 gap-10 px-0'}>
        <div className={'row align-items-center justify-content-between'}>
            <span className={'main-text'} style={{fontWeight: 500}}>{props.review.roomTitle} {props.review.roomSubtitle}</span>
            <div className={'relative'}>
                <Dots className={'pointer'} onClick={()=>setShowMenu(old=>!old)} />
                {showMenu && (
                    <div className={`col gap-10 ${styles.menu}`}>
                        <span className={`text-black pointer main-text ${styles.menuItem}`} onClick={()=>{
                            setShowMenu(false)
                            setShowEditPopup(true)
                        }}>Change review</span>
                        <span className={`text-black pointer main-text ${styles.menuItem}`} onClick={()=>{
                            setShowMenu(false)
                            setShowDeletePopup(true)
                        }}>Delete review</span>
                    </div>
                )}
            </div>
        </div>
          <span className={'text-12'}>{props.review?.roomAddress}</span>
          <div className={'row align-items-center justify-content-between col-22'}>
              <div className={'row justify-content-between align-items-center'}>
                  {renderStars()}
                  <span className={'ml-10 text-12'}>{props.review?.reviews?.date}</span>
              </div>
          </div>
          {((props.review?.reviews?.review && props.review?.reviews?.review.length<100) || showMore) && (
              <span>{props.review?.reviews?.review}</span>
          )}
          {(props.review?.reviews?.review && props.review?.reviews?.review.length>=100 && !showMore) && (
             <div className={'col px-0 gap-10'}>
                 <span>{props.review?.reviews?.review.substring(0, 100)} ... </span>
                 <span className={'blue-text pointer'} onClick={()=>setShowMore(true)}>...show more</span>
             </div>
          )}
          {showEditPopup && (
              <PopupEditReviewComponent review={props.review?.reviews} title={props.review.roomTitle}  onClose={()=>setShowEditPopup(false)}  onButton1Click={handleReviewUpdate}/>
          )}
          {showDeletePopup && (
              <PopupComponent title={'Delete this review'} text={'You can’t restore deleted review'} onClose={()=>setShowDeletePopup(false)} onButton1Click={()=>setShowDeletePopup(false)} buttonText1={'Cancel'} buttonText2={'Delete anyway'} onButton2Click={handleReviewDelete} />
          )}
      </div>
  )
}

const ReviewsComponent:React.FC<ReviewsPropsInterface> = ({user}) => {
    const [reviews, setReviews] = useState<any[]>([])

    function fetchReviews() {
        axios.get(`https://gscoworking.com/api/reviews/get-by-user-id/${user}`).then((answer) => {
            setReviews(answer.data)
        });
    }

    useEffect(()=>{
        fetchReviews()
    }, [])

    return (
        <div className={'col wrap gap-30'}>
            {reviews.map(review=>(
                <Review callback={fetchReviews} review={review} />
            ))}
        </div>
    );
};

export default ReviewsComponent;
export {};
