import React, {useEffect, useState} from 'react';
import {ReactComponent as CalendarIcon} from '../../../assets/images/svg/CalendarIconSea.svg';
import {ReactComponent as ClockIcon} from '../../../assets/images/svg/ClockIconSea.svg';
import {ReactComponent as KeyIcon} from '../../../assets/images/svg/Key.svg';
import {ReactComponent as PinIcon} from '../../../assets/images/svg/PinSea.svg';
import {ReactComponent as AttentionIcon} from '../../../assets/images/svg/RedAttintionOutlined.svg';
import {ReactComponent as CashIcon} from '../../../assets/images/svg/CardsIcon.svg';
import {ReactComponent as RoomIcon} from '../../../assets/images/svg/RoomSeaIcon.svg';
import {ReactComponent as SeaStar} from '../../../assets/images/svg/EmptySeaStarIcon.svg';
import {ReactComponent as TrashIcon} from '../../../assets/images/svg/TrashIconDark.svg';
import axios from "axios";
import UniversalPopup from "../Popup/Popup.component";
import BookingWidgetTestComponent from "../BookingWidget/BookingWidgetTest.component";
import BookingWidgetTestComponentMobile from "../../mobile/RoomDetailsPage/BookingWidgetTest.component";
import {saveBookedSlots} from "../../../reducers/bookingSlice";
import {useDispatch, useSelector} from "react-redux";
import LoaderComponent from "../../mobile/Loader/Loader.component";
import styles from './ActiveBooking.module.scss'
import {RootState} from "../../../reducers/types";

interface ActiveBookingPropsInterface {
    date: string;
    slot: any;
    address: string;
    keyCode: string;
    withPrice?: boolean;
    withDeletion?: boolean;
    readonly?: boolean;
    amount?: number | null;
    id?: string | null;
    handleOrdersChange? : any;
    width?: number;
    canceled?: boolean;
    title: string;
    order?: any;
}

const ActiveBookingComponent:React.FC <ActiveBookingPropsInterface> = ({date, slot, address, keyCode, withPrice= false, amount = null, withDeletion = false, id= null, handleOrdersChange = ()=>{}, width = 24, canceled = false, title, readonly= false, order=null}) => {
    const dispatch = useDispatch()
    const [popup, setPopup] = useState<any>(null)
    const [loading, setLoading] = useState<any>(false)
    const [editBookingDialog, setEditBookingDialog] = useState<boolean>(false)
    const building = useSelector((state: RootState)=>state.buildings.buildings)
    const [currentRoom, setCurrentRoom] = useState<any>(null)
    function formatTimeTo12HourFormat(hour: any) {
        const amOrPm = hour >= 12 ? 'pm' : 'am';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const formattedHourWithLeadingZero = formattedHour.toString().padStart(2, '0');


        return `${formattedHourWithLeadingZero} ${amOrPm}`;
    }

    function handleDeletePress() {
        setPopup({
            title: 'Please, confirm',
            text: 'Are you sure you want to delete booking?',
            buttonText1: 'Cancel',
            buttonText2: 'Delete card',
            onButton1Click: () => {
               setPopup(null)
            },
            onButton2Click: () => {
               deleteBooking()

            },
        });
        return
    }
    function formatCurrentDate(date: Date) {
        const currentDate = date;
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        return `${day}.${month}.${year}`;
    }
    function updateOrder(data:any, amount:any) {
        setLoading(true)
        let updateData = {slots: data, status: 'Edited'}
        axios.put(`https://gscoworking.com/api/orders/${id}`, updateData).then((answer)=>{
            setEditBookingDialog(false)
            handleOrdersChange()
            setLoading(false)
        });
    }

    function deleteBooking() {
        let updateData = {status: 'Canceled'}
        axios.put(`https://gscoworking.com/api/orders/${id}`, updateData).then((answer)=>{
            handleOrdersChange()
            setPopup(null)
        });
    }

    function toggleEditSlot() {
        setEditBookingDialog((old)=>!old)
    }

    const isDeleteAllowed = () => {
        const currentDate: any = new Date();
        const orderDate: any = new Date(slot[0].date);
        orderDate.setUTCHours(parseInt(slot[0].start, 10), 0, 0, 0); // Установить время на полночь (00:00:00)

        // Вычислить разницу во времени в миллисекундах
        const timeDifferenceInMilliseconds = orderDate - currentDate;

        // Проверить, находится ли дата заказа в будущем, и превышает ли разница во времени 24 часа
        if (timeDifferenceInMilliseconds > 0 && timeDifferenceInMilliseconds > 24 * 60 * 60 * 1000) {
            return true; // Удаление разрешено
        } else {
            return false; // Удаление не разрешено
        }
    };

    function getDayName(dateStr: string) {
        // Split the date string by dot to extract day, month, and year
        const [day, month, year] = dateStr.split(".");
        // Create a new date object (Note: month is 0-indexed in JavaScript Date)
        const date = new Date(`${month}/${day}/${year}`);
        // Array of day names
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        // Get the day of the week from the date object
        const dayOfWeek = date.getDay();
        // Return the day name
        return days[dayOfWeek];
    }

    useEffect(() => {
       if (order) {
           const rFromRedux = building[0].rooms.filter((x: any)=>x._id===order.roomID._id)
           if (rFromRedux && rFromRedux.length) {
               setCurrentRoom(rFromRedux[0])
           }
       }
    }, []);

    return (
        <div className={'row gap-20'}>
            <div className={`col gap-30 col-${width}`} style={readonly ? {opacity: 0.5} : {opacity: 1}}>
                <div className={'row align-items-center justify-content-between'}>
                    <div className={'row align-items-center gap-18'}>
                        <CalendarIcon/>
                        <span>{date}</span>

                    </div>
                    <span>{getDayName(date)}</span>
                    {withDeletion && isDeleteAllowed() && !canceled && (
                        // <TrashIcon className={'text-14 pointer'} onClick={handleDeletePress} style={{opacity: 0.5}}
                        // />
                        <span className={'magenta-text underline pointer'} onClick={() => toggleEditSlot()}>Edit</span>
                    )}
                    {readonly && (
                        <div className={'row align-items-center pointer'}>
                            <SeaStar/>
                            <SeaStar/>
                            <SeaStar/>
                            <SeaStar/>
                            <SeaStar/>
                        </div>
                    )}
                </div>

                <div className={'row align-items-center gap-18'}>
                    <RoomIcon />
                    <span>{title}</span>
                </div>
                <div className={'row align-items-center gap-18'}>
                    <ClockIcon />
                    <span>{`${formatTimeTo12HourFormat(slot[0].start)} - ${formatTimeTo12HourFormat(slot[0].end)}`}</span>
                </div>
                <div className={'row align-items-center gap-18'}>
                    <PinIcon />
                    <span className={'review-address'}>{address}</span>
                </div>
                {!readonly && (
                    <div className={'row align-items-center gap-18'}>
                        <KeyIcon />
                        <span>{keyCode}#</span>
                    </div>
                )}
                {withPrice && (
                    <div className={'row align-items-center gap-18'}>
                        <CashIcon />
                        <span>{amount} €</span>
                    </div>
                )}
                {!readonly && (
                    <div className={'row align-items-center gap-18'}>
                        <AttentionIcon />
                        <span className={'text-12'}>You have the option to make changes to your booking up to 24 hours before the scheduled date and time

</span>
                    </div>
                )}
                {popup && (
                    <UniversalPopup // This should be the Popup component from usePopup
                        title={popup.title}
                        text={popup.text}
                        onClose={()=>setPopup(null)}
                        buttonText1={popup.buttonText1}
                        buttonText2={popup.buttonText2}
                        onButton1Click={popup.onButton1Click}
                        onButton2Click={popup.onButton2Click}
                    />
                )}
            </div>
            {editBookingDialog && order && order.roomID && (
                <div className={`col gap-30 col-${23-width} ${styles.editForm}`}>
                    {window.innerWidth>500 && (
                        <BookingWidgetTestComponent date={formatCurrentDate(new Date(slot[0].date))} hours={(slot[0].end === 0 ? 24 : slot[0].end)-slot[0].start} callback={updateOrder} room={{...currentRoom, price: '0', oldPrice: '0', minHours: (slot[0].end === 0 ? 24 : slot[0].end)-slot[0].start}} buttonText={'Change'} multiple={false} mode={'edit'} preselected={[slot[0]]}/>
                    )}
                    {window.innerWidth<500 && (
                        <BookingWidgetTestComponentMobile date={formatCurrentDate(new Date(slot[0].date))} hours={(slot[0].end === 0 ? 24 : slot[0].end)-slot[0].start} callback={updateOrder} room={{price: '0', minHours: (slot[0].end === 0 ? 24 : slot[0].end)-slot[0].start, oldPrice: '0', ...currentRoom}} buttonText={'Change'} multiple={false} mode={'edit'} preselected={[slot[0]]}/>
                    )}
                </div>
            )}
            {loading && (
                <LoaderComponent text={'Please wait. Your booking is processing'} loading={loading} >
                </LoaderComponent>
            )}
        </div>
    );
};

export default ActiveBookingComponent;
export {};
