// src/reducers/authSlice.ts

import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from './types';
import axios from "axios"; // Import the RootState type

export interface BuildingState {
    buildings: any[];
    loading: boolean;
    error: any;
}

const initialState: BuildingState = {
    buildings: [],
    loading: false,
    error: null,
};

// Thunk action-креатор для получения данных о зданиях с API
export const fetchBuildingsAsync = createAsyncThunk('buildings/fetchBuildings', async () => {
    try {
        const response = await axios.get('https://gscoworking.com/api/buildings');
        return response.data;
    } catch (error) {
        throw new Error('Не удалось получить данные о зданиях с API');
    }
});

const buildingSlice = createSlice({
    name: 'buildings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBuildingsAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBuildingsAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.buildings = action.payload;
            })
            .addCase(fetchBuildingsAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const selectBuildingState = (state: RootState) => state.buildings;


// export const { loginSuccess, logout } = authSlice.actions;

export default buildingSlice.reducer;
export const selectAllBuildings = createSelector(
    selectBuildingState,
    (buildingState) => buildingState.buildings
);
export const selectAllIncludeNames = createSelector(
    selectBuildingState,
    (buildingState) => {
        const uniqueIncludeNamesSet = new Set<string>(); // Using a Set to ensure uniqueness

        for (const building of buildingState.buildings) {
            for (const room of building.rooms) {
                for (const include of room.includes) {
                    if (include.showInFilters===true) {
                        uniqueIncludeNamesSet.add(include.name);
                    }
                }
            }
        }

        return Array.from(uniqueIncludeNamesSet);
    }
);
export const selectAllRoomNames = createSelector(
    selectBuildingState,
    (buildingState) => {
        const allRoomTitlesArray: string[] = [];

        for (const building of buildingState.buildings) {
            for (const room of building.rooms) {
                allRoomTitlesArray.push(room.title);
            }
        }

        return allRoomTitlesArray;
    }
);
export const selectBuildingById = (buildingId: any) =>
    createSelector(selectAllBuildings, (buildings) =>
        buildings.find((building) => building.id === buildingId)
    );

export const selectRoomById = (roomId: string | undefined) =>
    createSelector(selectBuildingState, (buildingState) => {
        for (const building of buildingState.buildings) {
            const room = building.rooms.find((r: { id: string; }) => r.id === roomId);
            if (room) {
                return room;
            }
        }
        return null;
    });

export const getAllReviewsByBuildingId = (buildingId: string | undefined) =>
    createSelector(selectAllBuildings, (buildings) => {
        const building = buildings.find((b) => b.id === buildingId);
        if (building) {
            const reviews = building.rooms.flatMap((room: { reviews: any; }) => room.reviews);
            return reviews.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
        }
        return [];
    });

export const getRoomReviews = (roomId: string) =>
    createSelector(selectAllBuildings, (buildings) => {
        for (const building of buildings) {
            const room = building.rooms.find((r: any) => r.id === roomId);
            if (room) {
                return room.reviews.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
            }
        }
        return [];
    });

export const selectBuildingByRoomId = (roomId: string | undefined) =>
    createSelector(selectBuildingState, (buildingState) => {
        for (const building of buildingState.buildings) {
            const room = building.rooms.find((r: { id: string }) => r.id === roomId);
            if (room) {
                return building;
            }
        }
        return null;
    });
